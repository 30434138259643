const Panels = {
  RESPIRATORY_RTPCR_24_QPCR: {
    text: "Respiratory RT-PCR 24 qPCR",
    externalLink: "https://ntllaboratory.com/home/rpp",
    description: "Respiratory RT-PCR 24 qPCR is an advanced diagnostic test that combines reverse transcription-polymerase chain reaction (RT-PCR) and real-time polymerase chain reaction (qPCR) technologies to detect respiratory pathogens. It is a highly sensitive and specific test that can simultaneously identify up to 24 different respiratory pathogens in a single run.",
    sampleType: ["Nasopharyngeal", "Nares", "Oropharyngeal", "Cough Sputum", "BAL"],
    germens: [
      {
        display: "Adenovirus",
        category: "NONE",
        type: "virus"
      },
      {
        display: "Bordetella Pertussis",
        category: "SLOW_GROWING",
        type: "bacteria"
      },
      {
        display: "Chlamydophila Pneumoniae",
        category: "SLOW_GROWING",
        type: "bacteria"
      },
      {
        display: "Influenza A",
        category: "NONE",
        type: "virus"
      },
      {
        display: "Influenza B",
        category: "NONE",
        type: "virus"
      },
      {
        display: "Haemophilus Influenza",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Human Bocavirus",
        category: "NONE",
        type: "virus"
      },
      {
        display: "Human Corona 229E",
        category: "NONE",
        type: "virus"
      },
      {
        display: "Human Corona OC43",
        category: "NONE",
        type: "virus"
      },
      {
        display: "Human Corona NL63",
        category: "NONE",
        type: "virus"
      },
      {
        display: "Human Corona HKU1",
        category: "NONE",
        type: "virus"
      },
      {
        display: "Human Enterovirus",
        category: "NONE",
        type: "virus"
      },
      {
        display: "Human Metapneumoviruses",
        category: "NONE",
        type: "virus"
      },
      {
        display: "Human Parechovirus",
        category: "NONE",
        type: "virus"
      },
      {
        display: "Human Rhinovirus",
        category: "NONE",
        type: "virus"
      },
      {
        display: "Legionella Pneumophila",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Mycoplasma Pneumoniae",
        category: "SLOW_GROWING",
        type: "bacteria"
      },
      {
        display: "Parainfluenza 1",
        category: "NONE",
        type: "virus"
      },
      {
        display: "Parainfluenza 2",
        category: "NONE",
        type: "virus"
      },
      {
        display: "Parainfluenza 3",
        category: "NONE",
        type: "virus"
      },
      {
        display: "Parainfluenza 4",
        category: "NONE",
        type: "virus"
      },
      {
        display: "Respiratory Syncytial Virus A/B",
        category: "NONE",
        type: "virus"
      },
      {
        display: "SARS-CoV-2 by PCR",
        category: "NONE",
        type: "virus"
      },
      {
        display: "Streptococcus Pneumoniae",
        category: "STREPTOCOCCUS_SPECIES",
        type: "bacteria"
      }
    ],
    antibioticResistanceGenes: [
      {
        class: "Beta Lactams",
        display: "Beta Lactams",
        genes: [
          "CTX-M ESBL"
        ]
      },
      {
        class: "Carbapenem Resistant",
        display: "Carbapenem Resistant",
        genes: [
          "KPC",
          "NDM",
          "VIM",
          "IMP",
          "OXA-48"
        ]
      },
      {
        class: "Methicillin",
        display: "Methicillin",
        genes: [
          "mecA",
          "mecC"
        ]
      },
      {
        class: "Fluoroquinolone",
        display: "Fluoroquinolone",
        genes: [
          "Qnr"
        ]
      },
      {
        class: "Sulfonamide",
        display: "Sulfonamide",
        genes: [
          "Sul"
        ]
      },
      {
        class: "Trimethoprim",
        display: "Trimethoprim",
        genes: [
          "dfrA"
        ]
      },
      {
        class: "Vancomycin",
        display: "Vancomycin",
        genes: [
          "VanA",
          "VanB"
        ]
      }
    ],
    technicalSpecifications: ["100 Reactions/Kit", "Liquid format or 96 well prefilled plates"]
  },
  STREPTOCOCCUS_PYOGENES_QPCR: {
    text: "Streptococcus Pyogenes qPCR",
    externalLink: "https://ntllaboratory.com/home/rpp",
    description: "The Streptococcus pyogenes qPCR lab panel refers to a diagnostic test used to detect the presence of Streptococcus pyogenes bacteria in a biological sample. Streptococcus pyogenes, also known as Group A Streptococcus (GAS), is a bacterium responsible for various infections, including strep throat, skin infections, and invasive diseases.",
    sampleType: ["Nasopharyngeal", "Nares", "Oropharyngeal", "Cough Sputum", "BAL"],
    germens: [
      {
        display: "Strep Group A",
        category: "NONE",
        type: "bacteria"
      }
    ],
    technicalSpecifications: ["Add-on test or sold separately", "100 Reactions/Kit", "Liquid format or 96 well prefilled plates"]
  },
  EPSTEIN_BARR_VIRUS: {
    text: "Epstein-Barr Virus",
    externalLink: "https://ntllaboratory.com/home/molecular",
    sampleType: ["Nasopharyngeal", "Nares", "Oropharyngeal", "Cough Sputum", "BAL"],
    description: "Epstein- Barr virus(EBV) is a common herpesvirus that is primarily transmitted through saliva.It is responsible for infectious mononucleosis, characterized by symptoms such as fatigue, sore throat, fever, and swollen lymph nodes.EBV is also associated with various cancers and autoimmune disorders.Diagnosis of EBV infection is typically done through blood tests.",
    germens: [
      {
        display: "Epstein-Barr Virus",
        category: "NONE",
        type: "virus"
      }
    ],
    technicalSpecifications: ["Add-on test or sold separately", "100 Reactions/Kit", "Liquid format or 96 well prefilled plates"]
  },
  BACTERIAL_PNEUMONIA_QPCR: {
    text: "Bacterial Pneumonia qPCR",
    externalLink: "",
    description: "Bacterial Pneumonia qPCR is a diagnostic test that utilizes the quantitative polymerase chain reaction (qPCR) technique to detect and identify bacterial pathogens causing pneumonia. By analyzing the genetic material (DNA) extracted from a patient's respiratory sample, qPCR can identify specific bacterial species or genetic markers associated with bacterial pneumonia. This test provides rapid and accurate results, allowing healthcare professionals to determine the causative bacterial agent and guide appropriate treatment decisions for patients with pneumonia.",
    sampleType: ["Nasopharyngeal", "Nares", "Oropharyngeal", "Cough Sputum", "BAL"],
    germens: [
      {
        display: "Acinetobacter Calcoaceticus-Baumannii Complex",
        category: "PROTOZOAL",
        type: "bacteria"
      },
      {
        display: "Chlamydophila Pneumoniae",
        category: "SLOW_GROWING",
        type: "bacteria"
      },
      {
        display: "Enterobacter Cloacae Complex",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Escherichia Coli",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Haemophilus Influenzae",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Klebsiella Aerogenes",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Klebsiella Oxytoca",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Klebsiella Pneumoniae",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Legionella Pneumophila",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Moraxella Catarrhalis",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Mycoplasma Pneumoniae",
        category: "SLOW_GROWING",
        type: "bacteria"
      },
      {
        display: "Proteus Spp.",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Pseudomonas Aeruginosa",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Serratia Marcescens",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Staphylococcus Aureus",
        category: "STREPTOCOCCUS_SPECIES",
        type: "bacteria"
      },
      {
        display: "Streptococcus Agalactiae",
        category: "STREPTOCOCCUS_SPECIES",
        type: "bacteria"
      },
      {
        display: "Streptococcus Pneumoniae",
        category: "STREPTOCOCCUS_SPECIES",
        type: "bacteria"
      },
      {
        display: "Streptococcus Pyogenes",
        category: "STREPTOCOCCUS_SPECIES",
        type: "bacteria"
      },
    ],
    antibioticResistanceGenes: [
      {
        class: "Beta Lactams",
        display: "Beta Lactams",
        genes: [
          "CTX-M ESBL"
        ]
      },
      {
        class: "Carbapenem Resistant",
        display: "Carbapenem Resistant",
        genes: [
          "KPC",
          "NDM",
          "VIM",
          "IMP",
          "OXA-48"
        ]
      },
      {
        class: "Methicillin",
        display: "Methicillin",
        genes: [
          "mecA",
          "mecC"
        ]
      },
      {
        class: "Fluoroquinolone",
        display: "Fluoroquinolone",
        genes: [
          "Qnr"
        ]
      },
      {
        class: "Sulfonamide",
        display: "Sulfonamide",
        genes: [
          "Sul"
        ]
      },
      {
        class: "Trimethoprim",
        display: "Trimethoprim",
        genes: [
          "dfrA"
        ]
      },
      {
        class: "Vancomycin",
        display: "Vancomycin",
        genes: [
          "VanA",
          "VanB"
        ]
      }
    ],
    technicalSpecifications: ["100 Reactions/Kit", "Liquid format or 96 well prefilled plates"]
  },
  CANDIDA_VAGINITIS_QPCR: {
    text: "Candida Vaginitis qPCR",
    externalLink: "https://ntllaboratory.com/home/molecular",
    description: "Candida Vaginitis qPCR is a diagnostic test that employs the quantitative polymerase chain reaction (qPCR) technique to detect and identify Candida species causing vaginal yeast infections. By analyzing the genetic material (DNA) extracted from a patient's vaginal sample, qPCR can identify specific Candida species or genetic markers associated with Candida vaginitis. This test provides rapid and accurate results, enabling healthcare professionals to confirm the presence of Candida and guide appropriate treatment for individuals experiencing symptoms of vaginal yeast infections.",
    sampleType: ["Vaginal & Endocervical"],
    germens: [
      {
        display: "Candida Albicans",
        category: "FUNGAL",
        type: "bacteria"
      },
      {
        display: "Candida Dubliniensis",
        category: "FUNGAL",
        type: "bacteria"
      },
      {
        display: "Candida Glabrata",
        category: "FUNGAL",
        type: "bacteria"
      },
      {
        display: "Candida Parapsilosis",
        category: "FUNGAL",
        type: "bacteria"
      },
      {
        display: "Candida Tropicalis",
        category: "FUNGAL",
        type: "bacteria"
      }
    ],
    technicalSpecifications: ["100 Reactions/Kit", "Liquid format or 96 well prefilled plates"]
  },
  BACTERIAL_VAGINOSIS_QPCR: {
    text: "Bacterial Vaginosis qPCR",
    externalLink: "https://ntllaboratory.com/home/vaginosis",
    description: "Bacterial Vaginosis qPCR is a diagnostic test that utilizes the quantitative polymerase chain reaction (qPCR) technique to detect and identify bacteria associated with bacterial vaginosis (BV). By analyzing the genetic material (DNA) extracted from a patient's vaginal sample, qPCR can detect specific bacterial species or genetic markers commonly associated with BV, such as Gardnerella vaginalis and other bacteria. This test provides a rapid and accurate diagnosis, enabling healthcare professionals to identify the presence of BV-causing bacteria and guide appropriate treatment for individuals experiencing symptoms of bacterial vaginosis.",
    sampleType: ["Vaginal", "Endocervical"],
    germens: [
      {
        display: "Atopobium Vaginae",
        category: "ANAEROBE",
        type: "bacteria"
      },
      {
        display: "BVAB-2 / Megasphaera 1 / Megasphaera 2",
        category: "ANAEROBE",
        type: "bacteria",
        count: 3
      },
      {
        display: "Gardnella Vaginalis",
        category: "NONE",
        type: "bacteria"
      }
    ],
    technicalSpecifications: ["100 Reactions/Kit", "Liquid format or 96 well prefilled plates"]
  },
  STI_QPCR: {
    text: "STI qPCR",
    externalLink: "https://ntllaboratory.com/home/sti",
    description: "STI qPCR (Sexually Transmitted Infections qPCR) is a diagnostic test that utilizes the quantitative polymerase chain reaction (qPCR) technique to detect and identify various sexually transmitted infection (STI) pathogens. By analyzing the genetic material (DNA or RNA) extracted from a patient's biological sample, such as blood, urine, or swabs, qPCR can detect specific genetic markers or sequences associated with common STIs, including Chlamydia trachomatis, Neisseria gonorrhoeae, Human Papillomavirus (HPV), Trichomonas vaginalis, and others. This test provides fast and accurate results, allowing healthcare professionals to diagnose and manage STIs effectively, guiding appropriate treatment and prevention strategies for individuals at risk.",
    sampleType: ["Urine", "Vaginal", "Endocervical", "Penile"],
    germens: [
      {
        display: "Chlamydia Trachomatis",
        category: "PROTOZOAL",
        type: "bacteria"
      },
      {
        display: "Gardnerella Vaginalis",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Herpes Simplex Virus 1",
        category: "NONE",
        type: "virus"
      },
      {
        display: "Herpes Simplex Virus 2",
        category: "NONE",
        type: "virus"
      },
      {
        display: "Mycoplasma Genitalium",
        category: "SLOW_GROWING",
        type: "bacteria"
      },
      {
        display: "Mycoplasma Hominis",
        category: "SLOW_GROWING",
        type: "bacteria"
      },
      {
        display: "Neisseria Gonorrhoeae",
        category: "PROTOZOAL",
        type: "bacteria"
      },
      {
        display: "Trichomonas Vaginalis",
        category: "SLOW_GROWING",
        type: "bacteria"
      },
      {
        display: "Treponema Pallidum",
        category: "PROTOZOAL",
        type: "bacteria"
      },
      {
        display: "Ureaplasma Parvum",
        category: "SLOW_GROWING",
        type: "bacteria"
      },
      {
        display: "Ureaplasma Urealyticum",
        category: "SLOW_GROWING",
        type: "bacteria"
      }
    ],
    antibioticResistanceGenes: [
      {
        class: "Beta Lactams",
        display: "Beta Lactams",
        genes: [
          "CTX-M ESBL"
        ]
      },
      {
        class: "Carbapenem Resistant",
        display: "Carbapenem Resistant",
        genes: [
          "KPC",
          "NDM",
          "VIM",
          "IMP",
          "OXA-48"
        ]
      },
      {
        class: "Methicillin",
        display: "Methicillin",
        genes: [
          "mecA",
          "mecC"
        ]
      },
      {
        class: "Fluoroquinolone",
        display: "Fluoroquinolone",
        genes: [
          "Qnr"
        ]
      },
      {
        class: "Sulfonamide",
        display: "Sulfonamide",
        genes: [
          "Sul"
        ]
      },
      {
        class: "Trimethoprim",
        display: "Trimethoprim",
        genes: [
          "dfrA"
        ]
      },
      {
        class: "Vancomycin",
        display: "Vancomycin",
        genes: [
          "VanA",
          "VanB"
        ]
      }
    ],
    technicalSpecifications: ["100 Reactions/Kit", "Liquid format or 96 well prefilled plates"]
  },
  SARS_COV2_FLU_A_B_RSV_QPCR: {
    text: "Sars Cov 2, Flu A/B, RSV qPCR",
    externalLink: "https://ntllaboratory.com/home/molecular",
    description: "SARS-CoV-2, Flu A/B, RSV qPCR is a diagnostic test that utilizes the quantitative polymerase chain reaction (qPCR) technique to detect and differentiate between the viral pathogens SARS-CoV-2 (the virus causing COVID-19), Influenza A and B viruses (common flu viruses), and Respiratory Syncytial Virus (RSV). By analyzing the genetic material (RNA) extracted from a patient's respiratory sample, qPCR can detect specific genetic markers or sequences unique to each virus. This test allows healthcare professionals to accurately identify the presence of these respiratory viruses, enabling timely diagnosis, appropriate treatment decisions, and implementation of infection control measures to prevent the spread of these contagious respiratory infections.",
    sampleType: ["Nasopharyngeal", "Nares", "Oropharyngeal", "Cough Sputum", "BAL"],
    germens: [
      {
        display: "SARS-CoV-2",
        category: "NONE",
        type: "virus"
      },
      {
        display: "Influenza A/B",
        category: "NONE",
        type: "virus"
      },
      {
        display: "Respiratory Syncytial Virus A/B",
        category: "NONE",
        type: "virus"

      }
    ],
    technicalSpecifications: ["100 Reactions/Kit", "Liquid format or 96 well prefilled plates"]
  },
  SARS_COV2_QPCR: {
    text: "Sars Cov 2 qPCR",
    externalLink: "https://ntllaboratory.com/covid",
    description: "SARS-CoV-2 qPCR is a diagnostic test that utilizes the quantitative polymerase chain reaction (qPCR) technique to detect and identify the presence of the SARS-CoV-2 virus, responsible for the COVID-19 pandemic. By analyzing the genetic material (RNA) extracted from a patient's respiratory sample, qPCR can detect specific genetic markers or sequences unique to SARS-CoV-2. This test plays a crucial role in confirming the presence of the virus, aiding in early diagnosis, and facilitating appropriate public health measures such as isolation, contact tracing, and treatment. SARS-CoV-2 qPCR offers high sensitivity and specificity, allowing healthcare professionals to promptly identify infected individuals and mitigate the spread of the virus.",
    sampleType: ["Nasopharyngeal", "Nares", "Oropharyngeal", "Cough Sputum", "BAL"],
    germens: [
      {
        display: "SARS-CoV-2",
        category: "NONE",
        type: "virus"
      },
    ],
    technicalSpecifications: ["100 Reactions/Kit", "Liquid format or 96 well prefilled plates"]
  },
  GASTROINTESTINAL_INFECTION_QPCR: {
    text: "Gastrointestinal Infection qPCR",
    externalLink: "https://ntllaboratory.com/home/molecular",
    description: "Gastrointestinal Infection qPCR is a diagnostic test that utilizes the quantitative polymerase chain reaction (qPCR) technique to detect and identify various pathogens causing gastrointestinal infections. By analyzing the genetic material (DNA or RNA) extracted from a patient's stool or gastrointestinal sample, qPCR can detect specific genetic markers or sequences associated with common gastrointestinal pathogens, such as bacteria (e.g., Salmonella, Campylobacter), viruses (e.g., Norovirus, Rotavirus), and parasites (e.g., Giardia, Cryptosporidium). This test provides fast and accurate results, enabling healthcare professionals to identify the causative agents of gastrointestinal infections, guide appropriate treatment, and implement necessary infection control measures to prevent further transmission.",
    sampleType: ["Stool"],
    germens: [
      {
        display: "Sapovirus(GI / GII / GIV / GV)",
        category: "NONE",
        type: "virus"
      },
      {
        display: "Astrovirus",
        category: "NONE",
        type: "virus"
      },
      {
        display: "Norovirus(GI / GII)",
        category: "NONE",
        type: "virus"
      },
      {
        display: "Rotavirus(A)",
        category: "NONE",
        type: "virus"
      },
      {
        display: "Adenovirus",
        category: "NONE",
        type: "virus"
      },
      {
        display: "Salmonella Spp.",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Campylobacter Spp",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Vibrio Parahaemolyticus",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Vibrio Cholerae",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Yersinia Enterocolitica",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Plesiomonas ShigelloideB",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Shigella / Enteroinvasive E.Coli(EIEC)",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Enteroaggregative E.Coli(EAEC)",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Shiga Toxin Producing E.Coli(STEC)",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Enteropathogenic E.Coli(EPEC)",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Enterotoxigenic E.Coli(ETEC)",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Clostridium Difficile Toxin B",
        category: "ANAEROBE",
        type: "bacteria"
      },
      {
        display: "Clostridium Difficile Toxin A",
        category: "ANAEROBE",
        type: "bacteria"
      },
      {
        display: "Clostridium Difficile Binary Toxin A/B",
        category: "ANAEROBE",
        type: "bacteria"
      },
      {
        display: "Giarida Lamblia",
        category: "PROTOZOAL",
        type: "parasitic"
      },
      {
        display: "Entamoeba Histolytica",
        category: "PROTOZOAL",
        type: "parasitic"
      },
      {
        display: "Cryptosporidium Spp.",
        category: "PROTOZOAL",
        type: "parasitic"
      },
      {
        display: "Cyclospora Cayetanensis",
        category: "PROTOZOAL",
        type: "parasitic"
      },
      {
        display: "Vibrio Vulnificus",
        category: "PROTOZOAL",
        type: "parasitic"
      }
    ],
    antibioticResistanceGenes: [
      {
        class: "Beta Lactams",
        display: "Beta Lactams",
        genes: [
          "CTX-M ESBL"
        ]
      },
      {
        class: "Carbapenem Resistant",
        display: "Carbapenem Resistant",
        genes: [
          "KPC",
          "NDM",
          "VIM",
          "IMP",
          "OXA-48"
        ]
      },
      {
        class: "Methicillin",
        display: "Methicillin",
        genes: [
          "mecA",
          "mecC"
        ]
      },
      {
        class: "Fluoroquinolone",
        display: "Fluoroquinolone",
        genes: [
          "Qnr"
        ]
      },
      {
        class: "Sulfonamide",
        display: "Sulfonamide",
        genes: [
          "Sul"
        ]
      },
      {
        class: "Trimethoprim",
        display: "Trimethoprim",
        genes: [
          "dfrA"
        ]
      },
      {
        class: "Vancomycin",
        display: "Vancomycin",
        genes: [
          "VanA",
          "VanB"
        ]
      }
    ],
    technicalSpecifications: ["100 Reactions/Kit", "Liquid format or 96 well prefilled plates"]
  },
  H_PYLORI_WITH_CLARITHROMYCIN: {
    text: "H. Pylori with Clarithromycin",
    externalLink: "https://ntllaboratory.com/home/molecular",
    description: "H. Pylori with Clarithromycin qPCR is a diagnostic test that utilizes the quantitative polymerase chain reaction (qPCR) technique to detect and identify the presence of Helicobacter pylori bacteria with resistance to the antibiotic Clarithromycin. By analyzing the genetic material (DNA) extracted from a patient's gastric biopsy or stool sample, qPCR can detect specific genetic markers or mutations associated with H. pylori strains that have developed resistance to Clarithromycin. This test provides valuable information for healthcare professionals to guide appropriate treatment strategies, as Clarithromycin resistance is a significant factor in the management of H. pylori infections. Identifying Clarithromycin resistance allows for the selection of alternative antibiotics and improves the effectiveness of eradication therapy for H. pylori infections.",
    sampleType: ["Stool"],
    germens: [
      {
        display: "Helicobacter Pylori with Clarithromycin",
        category: "NONE",
        type: "bacteria"
      }
    ],
    technicalSpecifications: ["Add-on test or sold separately", "100 Reactions/Kit", "Liquid format or 96 well prefilled plates"]
  },
  MONKEY_POX_ORTHOPOX_SPECIES_QPCR: {
    text: "Monkey Pox Orthopox Species qPCR",
    externalLink: "https://ntllaboratory.com/home/molecular",
    description: "Monkeypox Orthopox Species qPCR is a diagnostic test that utilizes the quantitative polymerase chain reaction (qPCR) technique to detect and identify the presence of Monkeypox virus (a member of the Orthopoxvirus genus) in clinical samples. By analyzing the genetic material (DNA) extracted from a patient's skin lesion or other relevant sample, qPCR can detect specific genetic markers or sequences unique to the Monkeypox virus. This test provides rapid and accurate results, enabling healthcare professionals to diagnose Monkeypox infections, initiate appropriate treatment, and implement necessary infection control measures to prevent the spread of this rare viral disease.",
    sampleType: ["Lesion Swab"],
    germens: [
      {
        display: "Monkey Pox Virus",
        category: "NONE",
        type: "virus"
      },
      {
        display: "Orthopox",
        category: "NONE",
        type: "virus"
      }
    ],
    technicalSpecifications: ["250 Reactions/Kit", "Liquid format or 96 well prefilled plates"]
  },
  URINARY_TRACT_INFECTIONS_QPCR: {
    text: "Urinary Tract Infections qPCR",
    externalLink: "https://ntllaboratory.com/home/uti",
    description: "Urinary Tract Infections qPCR is a diagnostic test that utilizes the quantitative polymerase chain reaction (qPCR) technique to detect and identify the presence of bacterial pathogens causing urinary tract infections (UTIs). By analyzing the genetic material (DNA) extracted from a patient's urine sample, qPCR can detect specific genetic markers or sequences associated with common bacteria causing UTIs, such as Escherichia coli, Klebsiella pneumoniae, or Enterococcus faecalis. This test provides fast and accurate results, allowing healthcare professionals to identify the causative bacterial agents and guide appropriate antibiotic treatment for individuals experiencing symptoms of urinary tract infections. Early and accurate diagnosis is crucial for effective management of UTIs and prevention of complications.",
    sampleType: ["Urine"],
    germens: [
      {
        display: "Acinetobacter Baumannii",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Aerococcus Urinae",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Candida Albicans",
        category: "FUNGAL",
        type: "bacteria"
      },
      {
        display: "Candida Auris",
        category: "FUNGAL",
        type: "bacteria"
      },
      {
        display: "Candida Glabrata",
        category: "FUNGAL",

        type: "bacteria"
      },
      {
        display: "Candida Krusei",
        category: "FUNGAL",
        type: "bacteria"
      },
      {
        display: "Candida Parapsilosis",
        category: "FUNGAL",
        type: "bacteria"
      },
      {
        display: "Candida Tropicalis",
        category: "FUNGAL",
        type: "bacteria"
      },
      {
        display: "Citrobacter Freundii",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Corynebacterium Urealyticum",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Enterobacter Cloacae",
        category: "SLOW_GROWING",
        type: "bacteria"
      },
      {
        display: "Enterococcus Faecalis",
        category: "SLOW_GROWING",
        type: "bacteria"
      },
      {
        display: "Enterococcus Faecium",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Escherichia Coli",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Klebsiella Aerogenes",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Klebsiella Oxytoca",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Klebsiella Pneumoniae",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Morganella Morganii",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Proteus Mirabilis",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Proteus Vulgaris",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Providencia Stuartii",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Pseudomonas Aeruginosa",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Serratia Marcescens",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Staphylococcus Aureus",
        category: "STREPTOCOCCUS_SPECIES",
        type: "bacteria"
      },
      {
        display: "Staphylococcus Saprophyticus",
        category: "STREPTOCOCCUS_SPECIES",
        type: "bacteria"
      },
      {
        display: "Streptococcus Agalactiae",
        category: "STREPTOCOCCUS_SPECIES",
        type: "bacteria"
      },
      {
        display: "Ureaplasma Parvum",
        category: "SLOW_GROWING",
        type: "bacteria"
      },
      {
        display: "Ureaplasma Urealyticum",
        category: "SLOW_GROWING",
        type: "bacteria"
      },
      {
        display: "Treponema Pallidum",
        category: "PROTOZOAL",
        type: "bacteria"
      }
    ],
    antibioticResistanceGenes: [
      {
        class: "Beta Lactams",
        display: "Beta Lactams",
        genes: [
          "CTX-M ESBL"
        ]
      },
      {
        class: "Carbapenem Resistant",
        display: "Carbapenem Resistant",
        genes: [
          "KPC",
          "NDM",
          "VIM",
          "IMP",
          "OXA-48"
        ]
      },
      {
        class: "Methicillin",
        display: "Methicillin",
        genes: [
          "mecA",
          "mecC"
        ]
      },
      {
        class: "Fluoroquinolone",
        display: "Fluoroquinolone",
        genes: [
          "Qnr"
        ]
      },
      {
        class: "Sulfonamide",
        display: "Sulfonamide",
        genes: [
          "Sul"
        ]
      },
      {
        class: "Trimethoprim",
        display: "Trimethoprim",
        genes: [
          "dfrA"
        ]
      },
      {
        class: "Vancomycin",
        display: "Vancomycin",
        genes: [
          "VanA",
          "VanB"
        ]
      }
    ],
    technicalSpecifications: ["100 Reactions/Kit", "Liquid format or 96 well prefilled plates"]
  },
  CT_NG_TV: {
    text: "CT/NG/TV",
    externalLink: "https://ntllaboratory.com/home/molecular",
    description: "",
    sampleType: ["Urine", "Vaginal Swab"],
    germens: [
      {
        display: "Chlamydia Trachomatis",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Neisseria Gonorrhoeae",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Trichomonas Vaginalis",
        category: "NONE",
        type: "bacteria"
      }
    ],
    technicalSpecifications: ["100 Reactions/Kit", "Liquid format or 96 well prefilled plates"]
  },
  WOUND_QPCR: {
    text: "Wound qPCR",
    externalLink: "https://ntllaboratory.com/home/molecular",
    description: "Wound qPCR is a diagnostic test that utilizes the quantitative polymerase chain reaction (qPCR) technique to detect and identify bacterial pathogens present in wound infections. By analyzing the genetic material (DNA) extracted from a patient's wound swab or tissue sample, qPCR can detect specific genetic markers or sequences associated with common wound pathogens, such as Staphylococcus aureus, Pseudomonas aeruginosa, or Enterococcus species. This test provides rapid and accurate results, enabling healthcare professionals to identify the causative bacteria and guide appropriate treatment decisions, such as selecting the most effective antibiotics and implementing wound care strategies. Accurate diagnosis of wound infections through qPCR helps in promoting timely wound healing and preventing complications.",
    sampleType: ["Swab (Any Source)"],
    germens: [
      {
        display: "Acinetobacter Baumanni",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Enterobacter Cloacae Complex",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Escherichia Coli",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Proteus Spp.",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Klebsiella Oxytoca",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Klebsiella Pneumoniae",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Staphylococcus Saprophyticus",
        category: "STREPTOCOCCUS_SPECIES",
        type: "bacteria"
      },
      {
        display: "Burkholderia Spp.",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Enterococcus Faecalis/Faecium",
        category: "SLOW_GROWING",
        type: "bacteria"
      },
      {
        display: "Serratia Marcescens",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Morganella Morganii",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Streptococcus Pyogenes",
        category: "STREPTOCOCCUS_SPECIES",
        type: "bacteria"
      },
      {
        display: "Mycobacteroides Abcessus / Chelonae",
        category: "PROTOZOAL",
        type: "bacteria"
      },
      {
        display: "Pseudomanas Aeruginosa",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Clostridium Perfigens",
        category: "ANAEROBE",
        type: "bacteria"
      },
      {
        display: "Clostridium Septicum",
        category: "ANAEROBE",
        type: "bacteria"
      },
      {
        display: "Mycobacterium Avium Complex",
        category: "PROTOZOAL",
        type: "bacteria"
      },
      {
        display: "Citrobacter Freundii",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Clostridium Novyi",
        category: "ANAEROBE",
        type: "bacteria"
      },
      {
        display: "Mycobacterium Ulcerans / Marinum",
        category: "PROTOZOAL",
        type: "bacteria"
      },
      {
        display: "Mycobacterium Fortuitum",
        category: "PROTOZOAL",
        type: "bacteria"
      },
      {
        display: "Cutibacterium Spp.",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Providencia Stuartii",
        category: "NONE",

        type: "bacteria"
      },
      {
        display: "Klebisella Aerogenes",
        category: "NONE",
        type: "bacteria"
      },
      {
        display: "Staphylococcus Aureus",
        category: "STREPTOCOCCUS_SPECIES",
        type: "bacteria"
      },
      {
        display: "Streptococcus Agalactiae",
        category: "STREPTOCOCCUS_SPECIES",
        type: "bacteria"
      },
      {
        display: "Herpes simplex Virus 1",
        category: "NONE",
        type: "virus"
      },
      {
        display: "Staphylococcus Epidermidis",
        category: "STREPTOCOCCUS_SPECIES",
        type: "bacteria"
      },
      {
        display: "Varicella zoster Virus",
        category: "NONE",
        type: "virus"
      },
      {
        display: "Herpes simplex Virus 2",
        category: "NONE",
        type: "virus"
      }
    ],
    antibioticResistanceGenes: [
      {
        class: "Beta Lactams",
        display: "Beta Lactams",
        genes: [
          "CTX-M ESBL"
        ]
      },
      {
        class: "Carbapenem Resistant",
        display: "Carbapenem Resistant",
        genes: [
          "KPC",
          "NDM",
          "VIM",
          "IMP",
          "OXA-48"
        ]
      },
      {
        class: "Methicillin",
        display: "Methicillin",
        genes: [
          "mecA",
          "mecC"
        ]
      },
      {
        class: "Fluoroquinolone",
        display: "Fluoroquinolone",
        genes: [
          "Qnr"
        ]
      },
      {
        class: "Sulfonamide",
        display: "Sulfonamide",
        genes: [
          "Sul"
        ]
      },
      {
        class: "Trimethoprim",
        display: "Trimethoprim",
        genes: [
          "dfrA"
        ]
      },
      {
        class: "Vancomycin",
        display: "Vancomycin",
        genes: [
          "VanA",
          "VanB"
        ]
      }
    ],
  }
}



export { Panels }