import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { useState } from "react"

import "./App.css";
import { BodyChart } from "./BodyChart";
import Modal from "./Modal";
import { Panels } from "./Panels";

function App() {

  let [isOpen, setIsOpen] = useState(false)

  let [panel, setPanel] = useState(null)
  let [selectedCard, setSelectedCard] = useState(null)

  const setSelectedCardAndOpenModal = (card) => {
    window.gtag("event", "open_modal", {
      event_category: "open",
      event_label: card
    })
    setSelectedCard(card)
    setIsOpen(true)
    setPanel(Panels[card] ?? {})
  }


  return (
    <div className="h-full w-full">
      <div className="relative w-full h-full pt-16 pb-20 sm:pt-4 sm:pb-24">
        <div className="landscape:hidden absolute top-0 w-full flex justify-center pt-4">
          <a href="https://ntllaboratory.com/">
            <img className="h-12 w-auto" src="logo.png" alt="" />
          </a>
        </div>
        <div className="w-full h-full px-3">
          <BodyChart setSelectedCardAndOpenModal={setSelectedCardAndOpenModal} />
        </div>
        <div className="absolute w-full flex flex-col sm:flex-row bottom-0 items-center justify-center bg-gray-50 py-2 sm:py-4">
          <a href="https://ntllaboratory.com/">
            <img className="hidden landscape:block w-auto h-12" src="logo.png" alt="" />
          </a>
          <div className="text-gray-500 text-xs text-center sm:text-start font-medium pt-2 sm:pt-0 sm:pl-8 sm:-mt-1">
            <span>(847) 699-7100</span>
            <span className="mx-1">|</span>
            <a href="https://ntllaboratory.com/home/contact" className="hover:text-blue-600">Contact Us</a>
            <p className="pt-0.5">8833 Gross Point Rd, Skokie, IL 60077</p>
            <p className="pt-0.5">
              <a href="https://www.linkedin.com/company/ntl-laboratory" className="hover:text-blue-600">
                <span className="mr-1">
                  Follow Us: @ntllaboratory
                </span>
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </p>
          </div>
        </div>
      </div>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen} selectedCard={selectedCard} panel={panel} />
      {/* HACK OF THE MONTH */}
      <div>
        <span className="text-violet-800 bg-violet-100"></span>
        <span className="text-orange-800 bg-orange-100"></span>
        <span className="text-blue-800 bg-blue-100"></span>
        <span className="text-fuchsia-800 bg-fuchsia-100"></span>
        <span className="text-green-800 bg-green-100"></span>
        <span className="text-red-800 bg-red-100"></span>
      </div>
    </div>
  )
}

export default App;
